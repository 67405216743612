<template>
    <div>
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="diaform" :rules="rules" :label-width="formLabelWidth">
                    <el-form-item label="所属线路:" prop="routeName">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="diaform.routeName" readonly></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="专线名称:" prop="name">
                        <el-input v-model="diaform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="上行里程:" prop="upMileage">
                        <el-input v-model="diaform.upMileage">
                            <template slot="append">km</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="下行里程:" prop="downMileage">
                        <el-input v-model="diaform.downMileage">
                            <template slot="append">km</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="总里程:" prop="totalMileage">
                        <el-input v-model="diaform.totalMileage">
                            <template slot="append">km</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="专线任务:" prop="task">
                        <el-input v-model="diaform.task"></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="diaform.remark">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择所属机构" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',
            props: {
                children: 'children',
                label: 'text',
                value: 'id'
            },

            diaform: {
                routeName: '',
                routeId: '',
                name: '',
                upMileage: '',
                downMileage: '',
                totalMileage: '',
                task: '',
                remark: ''
            },
            dialogVisible: false,
            formLabelWidth: '120px',

            rules: {
                routeName: [{
                    required: true,
                    message: '请输入所属线路',
                    trigger: 'change'
                }],
                name: [{
                    required: true,
                    message: '请输入专线名称',
                    trigger: 'blur'
                }]
            }
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
                this.diaform.rentRouteId = ''
            }
        }
    },
    components: {
        institutionsTree
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.dialogVisible = true
            this.type = type || 'add'
            this.isCheck = false
            if (this.type == 'update') {
                this.title = '编辑'
                this.$nextTick(() => {
                    this.diaform = {
                      ...this.diaform,
                      ...JSON.parse(JSON.stringify(option))
                    }
                })
            } else {
                this.title = '新增'
            }
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        // 所属线路
        addInstitutions(data) {
            // console.log(data)
            this.diaform.routeId = data.id
            this.diaform.routeName = data.text
        },
        // 组织线路
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/baserentroute/save'
                    } else {
                        url = '/baserentroute/update'
                    }

                    let option = JSON.parse(JSON.stringify(this.diaform))
                    this.$http.post(url, option).then(res => {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            })
        }
    }
}

</script>
<style lang="scss" scoped>
</style>
